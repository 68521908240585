import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScColumnsResponsive,
  ScFlexAlignCenter,
  ScHeader3,
  ScImage,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBlackContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
    <ScBlackContainer>
      <ScMaxWidthContainer>
        <ScColumnsResponsive>
          <div style={{ flex: '1', paddingLeft: '1rem' }}>
            <ScHeader3>Psychologische Beratung</ScHeader3>
            <p>
              Psychologische Beratung unterstützt Menschen in schwierigen
              Lebenssituationen oder auch vor schwierigen Entscheidungen. In
              unserer schnelllebigen Zeit, wird immer mehr Leistung von jedem
              Einzelnen gefordert. Die Belastungen und Anforderungen nehmen zu,
              dadurch kann die Balance zwischen Körper, Geist und Seele rasch
              einmal in ein Ungleichgewicht geraten. Oft bedarf es nur einer
              Kleinigkeit, um das Leben aus den Fugen geraten zu lassen. Die
              professionellen Beratungsmethoden der psychologischen Beratung
              basieren auf gezielter und strukturierter Gesprächsführung unter
              Nutzung unterstützender Methoden im Sinne der
              WHO-Gesundheitsdefinition und haben einen präventiven Hintergrund.
              So kann das geistige, seelische, körperliche und soziale
              Wohlbefinden der Klienten gefördert werden.
            </p>
            <p>
              <h3>Schwerpunkte der psychologischen Beratung sind:</h3>
              <ul>
                <li>
                  Persönlichkeitsentfaltung und Steigerung des Selbstwertes{' '}
                </li>
                <li>Resilienz</li>
                <li>Stress und Burnout Prävention</li>
                <li>Kommunikationstraining</li>
                <li>Teamkonflikte</li>
                <li>Beenden von Mobbingsituationen</li>
                <li>Umgang mit Tod und Trauer</li>
                <li>Entscheidungsfindungen</li>
                <li>Partnerschaft und Familie</li>
                <li>Erziehung</li>
                <li>Sexualberatung</li>
                <li>Krisenintervention</li>
              </ul>
            </p>
          </div>
          <ScImage
            style={{
              width: '30rem',
              height: '23rem',
              margin: '1rem',
            }}
            src="pictures/pexels-alex-green-5699456.jpg"
          />
        </ScColumnsResponsive>
      </ScMaxWidthContainer>
    </ScBlackContainer>
  </Layout>
);

export default IndexPage;
